import { IStageConfig } from '../interfaces/main';

export const defaults: IStageConfig = {
  TEAM_NAME_SHORT: 'CWE',
  TEAM_NAME_FULL: 'Controlled Working Environment',
  TEAM_DOC_URL:
    'https://w.amazon.com/bin/view/InnovationEngineering/ControlledWorkingEnvironment/',
  PRODUCT_DOC_URL:
    'https://w.amazon.com/bin/view/InnovationEngineering/ControlledWorkingEnvironment/',

  EXCLUDE_REGEX: {
    DUO_TYPE: /^test-type-[a-f0-9-]{36}$/,
    DUO_PLATFORM: /^test-platform-[a-f0-9-]{36}$/,
    SUPPORT_TOPIC: /^test-topicName-[a-f0-9-]{36}$/
  }
};
