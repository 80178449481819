import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';

import { MainServiceApi } from '../../../../../../lib/api/mainServiceApi';

import { FlashbarProps, SelectProps } from '@awsui/components-react';
import {
  ICreateDuoPhone,
  IDuoPhone
} from '../../../../../../interfaces/integrations/duo';

import View from './view';
import { ConfigHelper } from '../../../../../../lib/config/helper';

interface IndexProps {
  refreshDuoPhoneTable: () => void;
  duoPhones: IDuoPhone[];
  hasDuoToken: boolean;
  setCreateDuoPhoneModalIsVisible: Dispatch<SetStateAction<boolean>>;
  setActivationCodeModalIsVisible: Dispatch<SetStateAction<boolean>>;
  activationCodeModalIsVisible: boolean;
}

const CreateDuoPhoneForm: FC<IndexProps> = ({
  refreshDuoPhoneTable,
  duoPhones,
  hasDuoToken,
  setCreateDuoPhoneModalIsVisible
}) => {
  const [formValues, setFormValues] = useState<ICreateDuoPhone>({
    number: '',
    confirmNumber: '',
    consented: false,
    name: '',
    type: '',
    platform: ''
  });

  const [duoPhoneId, setDuoPhoneId] = useState('');
  const [blockForm, setBlockForm] = useState(false);

  const [showSMSVerify, setShowSMSVerify] = useState(false);
  const [activationCodeModalIsVisible, setActivationCodeModalIsVisible] = useState(false);

  const [duoPhonePlatformsSelectValues, setDuoPhonePlatformsSelectValues] =
    useState<SelectProps.Options>([]);
  const [duoPhoneTypesSelectValues, setDuoPhoneTypesSelectValues] =
    useState<SelectProps.Options>([]);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [blockingFlashBarItems, setBlockingFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    if (duoPhones.length !== 0) {
      setBlockForm(true);
      setBlockingFlashBarItems([
        {
          type: 'warning',
          content: (
            <>
              You cannot add anymore DUO phones! Please remove your current DUO phone before attempting to add a new one. Contact your admin for more
              details.
            </>
          )
        }
      ]);
    } else if (hasDuoToken) {
      setBlockForm(true);
      setBlockingFlashBarItems([
        {
          type: 'warning',
          content: (
            <>
              You cannot re-register a new phone since you are using a hardware token.
              Contact support if you need assistance.
            </>
          )
        }
      ]);
    } else {
      setBlockingFlashBarItems([]);
      setBlockForm(false);
    }
  }, [duoPhones, hasDuoToken]);

  useEffect(() => {
    const api = new MainServiceApi();
    const typeExclude = ConfigHelper.get().EXCLUDE_REGEX.DUO_TYPE;
    const platformExclude = ConfigHelper.get().EXCLUDE_REGEX.DUO_PLATFORM;

    api
      .getAllDuoPlatforms()
      .then((data) => {
        const selectValues = data
          .filter((el) => !platformExclude.test(el.platformName))
          .map((el) => {
            return { label: el.platformName, value: el.platformName };
          });

        setDuoPhonePlatformsSelectValues(selectValues);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Platforms!</>
          }
        ]);
        return [];
      });
    api
      .getAllDuoTypes()
      .then((data) => {
        const selectValues = data
          .filter((el) => !typeExclude.test(el.typeName))
          .map((el) => {
            return { label: el.typeName, value: el.typeName };
          });

        setDuoPhoneTypesSelectValues(selectValues);
      })
      .catch(() => {
        setFlashBarItems((prevState) => [
          ...prevState,
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get DUO Types!</>
          }
        ]);

        return [];
      });
  }, []);

  // Step 1: collect phone data
  const handleSubmittedForm = async (data: ICreateDuoPhone) => {
    if (!blockForm) {
      const api = new MainServiceApi();
      return api.resetSMS(data.number).then(() => {
        setFormValues(data);
        setShowSMSVerify(true);

        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Sent SMS code for verification - enter it below</>
          }
        ]);
      }).catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Failed to send SMS verification - try again later</>
          }
        ]);
      });
    }
  };

  // Step 2: collect SMS code and submit request
  const handleSubmitSMSCode = async (smsCode: string) => {
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const { confirmNumber, consented, ...phone } = formValues;

    const api = new MainServiceApi();
    api
      .createDuoPhone({ phone, smsCode })
      .then((resp) => {
        setShowSMSVerify(false);
        setDuoPhoneId(resp.data.phoneId);
        refreshDuoPhoneTable();
        setFlashBarItems([]);
        setCreateDuoPhoneModalIsVisible(false)
        setActivationCodeModalIsVisible(true);
    })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to create DUO Phone! - {err}</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      flashBarItems={flashBarItems}
      setFlashBarItems={setFlashBarItems}
      blockingFlashBarItems={blockingFlashBarItems}
      duoPhonePlatformsSelectValues={duoPhonePlatformsSelectValues}
      duoPhoneTypesSelectValues={duoPhoneTypesSelectValues}
      duoPhoneId={duoPhoneId}
      blockForm={blockForm}
      setActivationCodeModalIsVisible={setActivationCodeModalIsVisible}
      activationCodeModalIsVisible={activationCodeModalIsVisible}
      showSMSVerify={showSMSVerify}
      setShowSMSVerify={setShowSMSVerify}
      handleSubmitSMSCode={handleSubmitSMSCode}
    />
  );
};
export default CreateDuoPhoneForm;
